import React from 'react'
import Grid from '@material-ui/core/Grid'
import BusinessIcon from '@material-ui/icons/Business'
import MailIcon from '@material-ui/icons/Mail'
import PhoneIcon from '@material-ui/icons/Phone'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import SiteLogo from '../Navbar/SiteLogo'
import SocialLinks from './SocialLinks'

import FirmaBilgisi from './FirmaBilgisi'
// const footerColor = '#212121'
const PageFooter = () => {
  return (
    <footer className="footer-main">
      {/* <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320' className='footer-svg'>
        <path fill={footerColor} fillOpacity='1' d='M0,192L1440,64L1440,320L0,320Z'></path>
      </svg> */}

      <div className="footer">
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} className="footerBlockWrapper">
            <div className="footerBlock">
              <SiteLogo />
              <br />
              <div className="p-text fw-500">EreZ Kaynak - Uğur Erkin DEMİRKAYA</div>
              <div className="p-text">İkitelli Org.San.Bölgesi Dersan Koop San.Sit.</div>
              <div className="p-text">Trios 2023 B Blok Kat 3 No:137 Başakşehir / İSTANBUL</div>

              <div className="social-links-wrapper">
                <SocialLinks />
              </div>
              <div>
                <small>{`© Copyright 2013 - ${new Date().getFullYear()} Her Hakkı Saklıdır.`}</small>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className="footerBlockWrapper">
            <div className="footerBlock">
              <div className="p-text">
                <div>
                  <BusinessIcon fontSize="large" color="secondary" />
                  <FirmaBilgisi />
                </div>
                <br />
                <div>
                  <a className="footer-link" href="mailto:info@erezkaynak.com" target="_top">
                    <MailIcon /> info@erezkaynak.com
                  </a>
                </div>
                <div>
                  <a className="footer-link" href="tel://02125493620" target="_top">
                    <PhoneIcon /> 0212 549 36 20
                  </a>
                </div>
                <div>
                  <a className="footer-link" href="tel://05352229557" target="_top">
                    <PhoneIphoneIcon /> 0535 222 95 57
                  </a>
                </div>
              </div>
              {/* <FooterRow text={<FirmaBilgisi />} icon={<BusinessIcon />} />
              <FooterRow to='mailto:info@erezkaynak.com' text='info@erezkaynak.com' target='_top' icon={<MailIcon />} />
              <FooterRow to='tel://02125493620' text='0212 549 36 20' icon={<PhoneIcon />} />
              <FooterRow to='tel://05352229557' text='0535 222 95 57' icon={<PhoneIphoneIcon />} /> */}
            </div>
          </Grid>
        </Grid>
      </div>
    </footer>
  )
}
export default PageFooter
